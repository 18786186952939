<template>
    <component :is="settingData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="settingData === undefined">
            <h4 class="alert-heading">Error fetching setting data</h4>
            <div class="alert-body">
                No setting found.
            </div>
        </b-alert>
    
        <b-tabs v-if="settingData" pills>
            <!-- Tab: Information -->
            <b-tab active>
                <template #title>
                      <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
                      <span class="d-none d-sm-inline">Invoice</span>
                </template>
        <setting-edit-tab-invoice
          :setting-data="settingData"
          :payment-data="paymentData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>


      <!-- Tab: Account 
      <b-tab v-if="userData.id > 0">
<template #title>
    <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
    <span class="d-none d-sm-inline">Permission</span>
</template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      -->
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import settingStoreModule from "../settingStoreModule";
import SettingEditTabInvoice from "./SettingEditTabInvoice.vue";


export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        SettingEditTabInvoice,
    },
    setup() {
        const settingData = ref(null);
        const paymentData = ref([])

        const SETTING_SETTING_STORE_MODULE_NAME = "setting-setting";

        const user = {
            id: 0
        }

        // Register module
        if (!store.hasModule(SETTING_SETTING_STORE_MODULE_NAME))
            store.registerModule(SETTING_SETTING_STORE_MODULE_NAME, settingStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SETTING_SETTING_STORE_MODULE_NAME))
                store.unregisterModule(SETTING_SETTING_STORE_MODULE_NAME);
        });

        const refetchData = () => {

            store
                .dispatch("setting-setting/fetchSettings", {})
                .then((response) => {
                    settingData.value = response.data.settings;
                    //console.log('settingData', settingData.value)
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        settingData.value = undefined;
                    }
                });


        };

        store
        .dispatch("setting-setting/fetchPaymentAccounts", {})
        .then((response) => {
            console.log('paymentAccount', response.data)
            paymentData.value = response.data.paymentaccounts;
       
        })
        .catch((error) => {
            if (error.response.status === 404) {
                settingData.value = undefined;
            }
        });


        refetchData()

        return {
            settingData,
            paymentData,
            refetchData,
        };
    },
};
</script>

<style>

</style>
