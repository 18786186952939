<template>
    <div>
        <!-- Header: Invoice -->
        <div class="d-flex">
            <h4 class="mb-0 ">Invoice Setting</h4>
        </div>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-overlay :show='loading' spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col cols="12">
                            <hr />
                        </b-col>
                        <!-- Field: Company Name En-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Name Eng" rules="required">
                                <b-form-group class="required" label="Company Name Eng" label-for="company_name" label-cols-md="4">
                                    <b-form-input id="company_name" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_name')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Name CN-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Name CN" rules="required">
                                <b-form-group class="required" label="Company Name CN" label-for="company_name_cn" label-cols-md="4">
                                    <b-form-input id="company_name_cn" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_name_cn')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: TCSP Licence No
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="TCSP Licence No" rules="required">
                                <b-form-group class="required" label="TCSP Licence No" label-for="tcsp_licence_no" label-cols-md="4">
                                    <b-form-input id="tcsp_licence_no" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('tcsp_licence_no')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        -->
                        <!-- Field: Company Address Eng-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Address Eng" rules="required">
                                <b-form-group class="required" label="Company Address Eng" label-for="company_address_en" label-cols-md="4">
                                    <b-form-input id="company_address_en" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_address_en')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Address CN-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Address CN" rules="required">
                                <b-form-group class="required" label="Company Address CN" label-for="company_address_cn" label-cols-md="4">
                                    <b-form-input id="company_address_cn" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_address_cn')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Tel-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Tel" rules="required">
                                <b-form-group class="required" label="Company Tel" label-for="company_tel" label-cols-md="4">
                                    <b-form-input id="company_tel" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_tel')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Fax-->
                        <b-col cols="12" md="12" v-if="false">
                            <validation-provider #default="validationContext" name="Company Fax" rules="required">
                                <b-form-group class="required" label="Company Fax" label-for="company_fax" label-cols-md="4">
                                    <b-form-input id="company_fax" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_fax')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Company Email-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Company Email" rules="required">
                                <b-form-group class="required" label="Company Email" label-for="company_email" label-cols-md="4">
                                    <b-form-input id="company_email" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('company_email')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Invoice Description-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Invoice Description" rules="required">
                                <b-form-group class="required" label="Invoice Description" label-for="invoice_description" label-cols-md="4">
                                    <b-form-input id="invoice_description" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('invoice_description')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Quotation Description-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Quotation Description" rules="required">
                                <b-form-group class="required" label="Quotation Description" label-for="quotation_description" label-cols-md="4">
                                    <b-form-input id="quotation_description" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('quotation_description')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Invoice Prefix-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Invoice Prefix" rules="required">
                                <b-form-group class="required" label="Invoice Prefix" label-for="invoice_prefix" label-cols-md="4">
                                    <b-form-input id="invoice_prefix" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('invoice_prefix')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Quotation Prefix-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Quotation Prefix" rules="required">
                                <b-form-group class="required" label="Quotation Prefix" label-for="quotation_prefix" label-cols-md="4">
                                    <b-form-input id="quotation_prefix" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('quotation_prefix')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Receipt Prefix-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Receipt Prefix" rules="required">
                                <b-form-group class="required" label="Receipt Prefix" label-for="receipt_prefix" label-cols-md="4">
                                    <b-form-input id="receipt_prefix" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('receipt_prefix')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Field: Number of Leading Zeros-->
                        <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Number of Leading Zeros" rules="required|integer|between:0,8">
                                <b-form-group class="required" label="Number of Leading Zeros" label-for="number_of_leading_zeros" label-cols-md="4" v-b-tooltip.hover title="By default, Invoice numbers would start as 1,2,3. Even if you have a prefix, it would like PREFIX-1, PREFIX-2. Setting a value here like 5 would make the display as 00001, 00011, 00111. It might help you have a visually appealing numbers. Set this to 0 to turn off this feature">
                                    <b-form-input id="number_of_leading_zeros" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('number_of_leading_zeros')].value" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                         <b-col cols="12" md="12">
                            <validation-provider #default="validationContext" name="Default Payment Account" rules="required">
                                <b-form-group class="required" label="Default Payment Account" label-for="default_payment_id" label-cols-md="4" v-b-tooltip.hover title="Default payment account of the invoice">
                                    <b-form-input style="display:none;" id="default_payment_id" :state="getValidationState(validationContext)" v-model="invoiceData[findInvoiceIndex('default_payment_id')].value" />
                                     <v-select 
                                     v-model="invoiceData[findInvoiceIndex('default_payment_id')].value" 
                                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                                     :options="paymentData" 
                                     label="description" 
                                     :reduce="(option) => option.id" 
                                     :clearable="false"
                                     >
                                            <template slot="option" slot-scope="option">
                                                {{option.description}} [{{option.bank_account}}]
                                            </template>
                                    </v-select>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-col cols="12">
                        <hr />
                    </b-col>
                    <!-- Form Actions -->
    
                    <div class="d-flex justify-content-end mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="ml-2" type="submit">
                            Edit
                        </b-button>
                    </div>
                </b-form>
            </b-overlay>
        </validation-observer>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import 'animate.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, between, integer } from "@validations";
import { heightTransition } from '@core/mixins/ui/transition'

export default {
    data() {
        return {
            required,
            between,
            integer
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BFormCheckbox,
        BFormDatepicker,
        BInputGroupAppend,
        BInputGroup,
        BFormInvalidFeedback,
        BOverlay,
        VBTooltip,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        onSubmit() {
            
            var data = this.invoiceData
            this.$swal({
                title: '確認儲存?',
                showCancelButton: true,
                confirmButtonText: '儲存',
                cancelButtonText: '取消',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            })
            .then(result => {
                if (result.value) {
                this.loading = true  
                store.dispatch("setting-setting/updateSetting", data)
                .then((response) => {
                    this.loading = false
                    this.$swal({
                        text: response.data.message,
                        icon: 'success',
                        customClass: {
                        confirmButton: 'btn btn-primary',
                        },
                        showClass: {
                        popup: 'animate__animated animate__bounceIn',
                        },
                        buttonsStyling: false,
                    })
                    this.$emit('refetch-data')
                }).catch((error) => {
                    this.loading = false
                    this.$swal({
                        text: JSON.stringify(error.response.data.message),
                        icon: 'error',
                        customClass: {
                        confirmButton: 'btn btn-primary',
                        },
                        showClass: {
                        popup: 'animate__animated animate__bounceIn',
                        },
                        buttonsStyling: false,
                    })
                })
                }
            })


        }
    },
    props: {
        settingData: {
            type: Array,
            required: true,
        },
        paymentData: {
            type: Array,
            required: true,
        }
    },
    setup(props, { emit }) {
        const invoiceData = ref([])
        const loading = ref(false)

        const resetInvoiceData = () => {
            invoiceData.value = props.settingData.filter(item => item.code == 'invoice')
        }

        resetInvoiceData()


        const findInvoiceIndex = (key) => {

            return invoiceData.value.findIndex(obj => obj.key_set == key)

        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetInvoiceData)

        return {
            invoiceData,
            findInvoiceIndex,
            loading,
            refFormObserver,
            getValidationState,
            resetForm,

        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.required>label::after {
    content: " **";
    color: red;
}

.vs__selected path {
    fill: white;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;
        .dark-layout & {
            background-color: unset;
        }
    }
}
</style>

