import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettings(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/setting/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/setting', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    updateSetting(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.put('/setting', {settings:data})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchPaymentAccounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment_account/list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
  }


  },
}
